import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import downloadAppApple from "../images/download_app_apple.png"
import downloadAppGoogle from "../images/download_app_google.png"
import cityStoriesApp from "../images/citystories_app.jpg"
import qrAppStore from "../images/qr_app_store.png"
import qrPlayStore from "../images/qr_play_store.png"

const DownloadPage = () => (
  <Layout>
    <Seo title="City Stories" />
    <div style={{ display: `flex`, paddingTop: `80px`, paddingBottom: `120px` }}>
      <div style={{ width: `65%`, paddingRight: `2%` }}>
        <div>
          Hallo,<br/>
          <br/>
          schön, dass du uns auf unserer citystories_bnx Webseite besuchst. CITY STORIES ist ein Projekt des <a href="https://www.bonn.de/stadtmuseum">Stadtmuseum Bonn</a> und des <a href="https://www.fringe-ensemble.de">fringe ensemble</a>, mit welchem wir dir einen neuen Blick auf die Stadt bieten möchten. CITY STORIES beruht auf dem Gedanken, dass sich die Geschichte einer Stadt aus den persönlichen Geschichten ihrer Bewohner*innen speist.<br/>
          <br/>
          Wir haben eine App entwickelt, mit deren Hilfe du dich durch Teile des Bonner Stadtgebiets zu verschiedenen Orten und Plätzen bewegen kannst. Per einfacher Navigation findest du dort von uns gestaltete Schilder vor. Im Umkreis von einigen Metern um die Schilder hörst du dann über das Smartphone bzw. Tablet Geschichten, die sich genau an diesem Ort ereignet haben. Alle Geschichten haben uns Bonner Bürger*innen gestiftet. Du wirst Überraschendes, Witziges, Berührendes und auf jeden Fall Neues über die Geschichte unserer Stadt erfahren.<br/>
          <br/>
          Die App ist per QR-Code an jedem Schild, im studio_bnx in der Bonner Innenstadt und auch hier direkt auf der Seite herunterladbar. Zum Start beinhaltet die App insgesamt 50 Orte und Plätze und entsprechend genauso viele Geschichten.<br/>
          <br/>
          Du kannst dir hier auf der Webseite einen Überblick verschaffen, die Geschichten nochmal hören oder lesen und vor allen Dingen auch mit einer Geschichte von dir, die du an einem konkreten Ort in Bonn erlebt hast, unsere Sammlung vergrößern.<br/>
          <br/>
          In der App findest du ebenfalls die Möglichkeit, deine Geschichte dem Projekt und damit dem Stadtmuseum zu spenden.<br/>
          <br/>
          CITYSTORIES ist ein Teil der Neuerfindung des <a href="https://www.bonn.de/stadtmuseum">Stadtmuseum Bonn</a>. Gemeinsam mit dir möchten wir Bonn kennenlernen. Wir suchen deine Geschichten und Erinnerungen. Es soll ein offener Ort von allen und für alle entstehen. Wir wollen in den Dialog über das Leben in Bonn treten. Es ist deine Stadt, deine Geschichte und dein Museum!<br/>
          <br/>
          Jetzt aber <Link to='/karte'>los und viel Spaß beim Entdecken von Bonn</Link>.
        </div>
      </div>
      <div style={{ width: `33%` }}>
        <div style={{ maxWidth: `95%` }}>
          <img style={{ maxWidth: `95%` }} src={cityStoriesApp} alt="City Stories App" /><br/>
        </div>
        <div style={{ maxWidth: `95%`, display: `flex`, alignItems: `center` }}>
          <a href="https://apps.apple.com/us/app/citystories-bnx/id1629714118"><img style={{ maxWidth: `90%` }} src={downloadAppApple} alt="City Stories App für iOS herunterladen" /></a>
          <img style={{ maxWidth: `25%` }} src={qrAppStore} alt="City Stories App QR Code iOS" />
        </div>
        <div style={{ maxWidth: `95%`, display: `flex`, alignItems: `center` }}>
          <a href="https://play.google.com/store/apps/details?id=com.Fehime.citystories_bnx"><img style={{ maxWidth: `90%` }} src={downloadAppGoogle} alt="City Stories für Goole Android herunterladen" /></a>
          <img style={{ maxWidth: `25%` }} src={qrPlayStore} alt="City Stories App QR Code Android" />
        </div>
      </div>
    </div>
  </Layout>
)

export default DownloadPage
